(function() {

    // Auto scrolling :
    const scrollIntoViewSettings = {
        block: 'start',
        behavior: 'smooth',
        inline: 'start'
    };
    const introLink = document.querySelector('#introLink');
    const firstLink = document.querySelector('#firstLink');

    introLink.addEventListener('click', function(e) {
        e.preventDefault()
        document.querySelector('#intro').scrollIntoView(scrollIntoViewSettings)
    })

    firstLink.addEventListener('click', function(e) {
        e.preventDefault()
        document.querySelector('#first').scrollIntoView(scrollIntoViewSettings)
    })

    // Appear on loading :
    window.addEventListener("load", function() {
        const body = document.querySelector('body')
        window.setTimeout(function() {
            body.classList.remove('is-preload');
        }, 100);
    });

    // Sticky header :
    window.addEventListener("scroll", function() {
        if (document.documentElement.scrollTop < 600) {
            nav.classList.remove('alt')
            return
        }
        if (document.documentElement.scrollTop > 600) {
            nav.classList.add('alt')
            return
        }
    });


})();